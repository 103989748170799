<template>
  <div>
    <validation-observer ref="createTeacherForm">
      <b-form ref="createuserForm">
        <b-modal
          dialog-class="create-new-teacher-modal-dialog"
          content-class="create-new-teacher-modal-content"
          v-model="isCreateAccountDialogVar" 
          scrollable
          size="sm"
          @hide="toggleCreateUserDialog(false)"
        >
          <template #modal-title>
            <strong v-if="userDto.userType == 1">
              إضافة مستخدم لوحة
            </strong>
            <strong v-if="userDto.userType == 2">
              إضافة أستاذ
            </strong>
            <strong v-if="userDto.userType == 3">
              إضافة مجيب أسئلة
            </strong>
            <strong v-if="userDto.userType == 4">
              إضافة نقطة بيع
            </strong>
            <strong v-if="userDto.userType == 5">
              إضافة مستخدم تطبيق
            </strong>
          </template>

          <b-form-group label="الاسم" label-for="teacherfirstName">
             <validation-provider
                  #default="{ errors }"
                  name="الاسم"
                  rules="required"
             >
            <b-form-input  v-model="userDto.firstName" id="teacherfirstName" placeholder="الاسم"
                   :state="errors.length > 0 ? false : null"
             />
             <small class="text-danger">{{ errors[0] }}</small>
             </validation-provider>
          </b-form-group>

          <b-form-group label="الكنية" label-for="teacherlastName">
            <validation-provider
                  #default="{ errors }"
                  name="الكنية"
                  rules="required"
             >
            <b-form-input v-model="userDto.lastName" id="teacherlastName" placeholder="الكنية" 
              :state="errors.length > 0 ? false : null"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="اسم المستخدم" label-for="username">
            <validation-provider
                  #default="{ errors }"
                  name="اسم المستخدم"
                  rules="required|no_spaces|english_letters_numbers"
             >
            <b-form-input v-model="userDto.username" id="username" placeholder="اسم المستخدم"
            :state="errors.length > 0 ? false : null"  />
             <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="البريد الالكتروني" label-for="email">
            <validation-provider
                  #default="{ errors }"
                  name="البريد الالكتروني"
                  rules="email"
             >
            <b-form-input v-model="userDto.email" id="email" placeholder="البريد الالكتروني" type="email"
             :state="errors.length > 0 ? false : null"/>
             <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>

          <b-form-group label="كلمة السر" label-for="password">
            <validation-provider
                  #default="{ errors }"
                  name="كلمة السر"
                  rules="required"
             >
            <b-form-input v-model="userDto.password" id="password" placeholder="كلمة السر" type="password"
             :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>

          <b-form-group label="تاريخ الميلاد" label-for="dob">
                        <validation-provider
                          v-slot="{ errors }"
                          name="تاريخ الميلاد"
                          rules="required"
                        >
                          <flat-pickr
                            id="dob"
                            v-model="userDto.dob"
                            class="form-control"
                            :config="config" 
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
          </b-form-group>
          
          <b-form-group label="رقم الهاتف" label-for="phoneNumber">
               <validation-provider
                  #default="{ errors }"
                  name="رقم الهاتف"
                  rules="required"
               >
            <b-form-input v-model="userDto.phoneNumber" id="phoneNumber" placeholder="رقم الهاتف" type="number" 
             :state="errors.length > 0 ? false : null"/>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>


          <b-form-group label="الحد المالي" label-for="moneyLimit" v-if="userDto.userType == 4">
               <validation-provider
                  #default="{ errors }"
                  name="الحد المالي"
                  rules="required"
               >
            <b-form-input v-model="userDto.moneyLimit" id="moneyLimit" placeholder="الحد المالي" type="number" 
             :state="errors.length > 0 ? false : null"/>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>

          <b-form-group v-if="userDto.userType == 3" label="المواد" label-for="subjectId">
            <!-- <validation-provider
              #default="{ errors }"
              name="المواد"
              rules="required"
            > -->
              <v-select
                id="subjectId"
                v-model="userDto.subjectIds"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                :options="subjectsList"
                multiple
                :reduce="(item) => item.id"
              ></v-select>
              <!-- <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider> -->
          </b-form-group>

          <b-form-group :label="userDto.userType == 3 ? 'قيمة السؤال' : 'النسبة'" label-for="rate" v-if="userDto.userType == 2 || 
                           userDto.userType == 3 ||
                           userDto.userType == 4">
               <validation-provider
                  #default="{ errors }"
                  name="النسبة"
                  rules="required"
               >
               <b-input-group :append="userDto.userType == 4 ? '%' : ''">
            <b-form-input v-model="userDto.rate" id="rate" placeholder="rate" type="number" 
             :state="errors.length > 0 ? false : null"/>
             </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>

          <b-form-group label="العنوان" label-for="posAddress" v-if="userDto.userType == 4">
            <validation-provider
                  #default="{ errors }"
                  name="العنوان"
                  rules="required"
             >
            <b-form-input v-model="userDto.posAddress" id="posAddress" placeholder="العنوان"
            :state="errors.length > 0 ? false : null"  />
             <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="إمكانية الحسم" 
                        label-for="discountAvailable" 
                        v-if="userDto.userType == 4">
          <b-form-checkbox
            v-model="userDto.discountAvailable"
            class="mr-0 mt-50"
            id="discountAvailable"
            switch
            inline
          />
        </b-form-group>


          <template #modal-footer>
            <b-button
              @click.prevent="submitPosForm"
              size="md"
              variant="primary"
            >
              إضافة
            </b-button>
            <b-button
              @click="toggleCreateUserDialog(false)"
              size="md"
              variant="outline-primary"
            >
              تراجع
            </b-button>
          </template>
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<style lang="scss">
.create-new-teacher-modal-dialog {
  margin: 0 !important;
  max-height: 100vh !important;
  height: 100vh;
}
.create-new-teacher-modal-content {
  max-height: 100vh !important;
  border-radius: 0 !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import { required , email , length , digits , no_spaces, english_letters_numbers } from '@validations';
import flatPickr from "vue-flatpickr-component";
import vSelect from 'vue-select'

import {
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BInputGroup
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    flatPickr,
    vSelect,
    BInputGroup
  },
  props:{
    type : String
  },
  data: () => ({
    isCreateAccountDialogVar: false,
    required,
    email,
    length,
    digits,
    no_spaces,
    english_letters_numbers,
    config : {
      allowInput : true,
      defaultDate : "1990-01-01"
    }
  }),
  created(){
   localize('ar')
   this.userDto.userType = +this.type
   this.getSubjectsList();
  },
  computed: {
    ...mapGetters(["isCreateUserDialog", "userDto","subjectsList"]),
  },
  methods: {
    ...mapActions(["toggleCreateUserDialog","createUser","getSubjectsList"]),
    submitPosForm() {
      this.$refs.createTeacherForm.validate().then((success) => {
        if (success) {
          
          this.createUser({ user : this.userDto , vue : this }).then(() => {
            
            requestAnimationFrame(() => {
              this.$refs.createuserForm.reset();
              this.toggleCreateUserDialog(false)
            });
          });
        }
      });
    },
  },
  watch: {
    isCreateUserDialog(newVal) {
      this.isCreateAccountDialogVar = newVal;
    },
    type(data){
        this.userDto.userType = data
    }
  },
};
</script>

