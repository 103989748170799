<template>
  <div>
    <b-card no-body>
      <b-card-header class="p-0">
        <b-button
          size="sm"
          variant="flat-secondary"
          class="btn-icon rounded-circle ml-auto"
          style="padding: 0.5rem 10px !important"
          :disabled="isNotSelectedRows"
          @click="getSelectedRow()"
        >
          <unicon name="trash-alt" width="18"></unicon>
        </b-button>
      </b-card-header>
      <b-card-body class="px-0">
        <vue-good-table
          @on-selected-rows-change="selectionChanged"
          :columns="columns[+type - 1]"
          :rows="activeTableUser"
          :rtl="false"
          :small="true"
          styleClass="vgt-table condensed"
          ref="accounts-table"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'fullName'" class="text-nowrap">
              <span class="text-nowrap">{{
                props.row.firstName + " " + props.row.lastName
              }}</span>
            </span>
            <span v-if="props.column.field === 'createdAt'">
              {{ new Date(props.row.createdAt).toISOString().substr(0, 10) }}
            </span>
            <span v-else-if="props.column.field === 'action'">
              <b-button
                :to="`/user/${props.row.userType}/${props.row.id}/details`"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                style="padding: 2px 6px !important"
              >
                <unicon name="ellipsis-v" width="18"></unicon>
              </b-button>
            </span>
            <span v-else-if="props.column.field === 'dateBlocked'">
              <b-badge class="px-1" :variant="props.row.dateBlocked == null ? 'light-success' : 'light-warning'">
                {{ props.row.dateBlocked == null ? 'مفعل' : 'غير مفعل'}}
              </b-badge>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
    <b-col cols="12" class="d-flex justify-content-center">
      <b-pagination
        :value="userFirstPage"
        :total-rows="mainUserList.length"
        :per-page="userPageLength"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="(value) => updateUserPagination(value)"
      >
        <template #prev-text>
          <unicon width="20" name="angle-right" fill="royalblue" />
        </template>
        <template #next-text>
          <unicon width="20" name="angle-right" fill="royalblue" />
        </template>
      </b-pagination>
    </b-col>
    <userCreate :type="this.type" />
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
  BBadge,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import { mapActions, mapGetters } from "vuex";
import userCreate from './user-create'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BBadge,
    userCreate
  },
  data() {
    return {
      ids : [],
      isNotSelectedRows: true,
      columns: [
      [
        {
          label: "الاسم الكامل",
          field: "fullName",
        },
        {
          label: "اسم المستخدم",
          field: "username",
        },
        {
          label: "تاريخ الإشتراك",
          field: "createdAt",
          sortable: false,
        },
        {
          label: "تفاصيل",
          field: "action",
          sortable: false,
        },
      ],
      [
        {
          label: "الاسم الكامل",
          field: "fullName",
        },
        {
          label: 'اسم المستخدم',
          field: 'username',
        },
        {
          label: 'تاريخ الإشتراك',
          field: 'createdAt',
          sortable: false,
        },
        {
          label: 'عدد الوحدات',
          field: 'count',
          sortable: false,
        },
        {
          label: 'تفاصيل',
          field: 'action',
          sortable: false,
        },
      ],
      [
        {
          label: "الاسم الكامل",
          field: "fullName",
        },
        {
          label: 'اسم المستخدم',
          field: 'username',
        },
        {
          label: 'كلمة المرور',
          field: 'password',
          sortable: false,
        },
        {
          label: 'تاريخ الإشتراك',
          field: 'createdAt',
          sortable: false,
        },
        {
          label: 'عدد الإجابات',
          field: 'count',
          sortable: false,
        },
        {
          label: 'الحالة',
          field: 'dateBlocked',
          sortable: false,
        },
        {
          label: 'تفاصيل',
          field: 'action',
          sortable: false,
        },
      ],
      [
        {
          label: "الاسم الكامل",
          field: "fullName",
        },
        {
          label: 'اسم المستخدم',
          field: 'username',
        },
        {
          label: 'كلمة المرور',
          field: 'password',
          sortable: false,
        },
        {
          label: 'العنوان',
          field: 'posAddress',
          sortable: false,
        },
        {
          label: 'رقم الهاتف',
          field: 'phoneNumber',
          sortable: false,
        },
        {
          label: 'تاريخ الإشتراك',
          field: 'createdAt',
          sortable: false,
        },
        {
          label: 'عدد الرموز المباعة',
          field: 'count',
          sortable: false,
        },
        {
          label: 'الحالة',
          field: 'dateBlocked',
          sortable: false,
        },
        {
          label: 'تفاصيل',
          field: 'action',
          sortable: false,
        },
      ],
      [
        {
          label: "الاسم الكامل",
          field: "fullName",
        },
        {
          label: 'رقم الهاتف',
          field: 'phoneNumber',
        },
        
        {
          label: 'كلمة المرور',
          field: 'password',
          sortable: false,
        },
        {
          label: 'تاريخ الإشتراك',
          field: 'createdAt',
          sortable: false,
        },
        {
          label: 'عدد الاشتراكات',
          field: 'count',
          sortable: false,
        },
        {
          label: 'الحالة',
          field: 'dateBlocked',
          sortable: false,
        },
        {
          label: 'تفاصيل',
          field: 'action',
          sortable: false,
        },
      ]
    ]};
  },
  props: {
    type: String,
  },
  computed: {
    ...mapGetters(['mainUserList',
                   'activeTableUser',
                   'userPageLength',
                   'userFirstPage'])
  },
  watch: {
    type(){
      store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
        isActive: true,
        text: "حساب جديد",
        fetchingFunction: this.toggleCreateUserDialog,
        placeHolder: "ابحث عن حساب محدد",
        value: "",
        filterFunc: this.filterUsers
      });
      if(this.type <= 5 && this.type > 0) {
      this.fetchUsers({ id: 0, type: this.type });
    }
    else{
      this.$router.push('/error-404')
    }
    }
  },
  mounted() {
    store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
        isActive: true,
        text : "حساب جديد",
        // text: this.type == 1 ? "حساب جديد" :
        //       this.type == 2 ? "أستاذ جديد" :
        //       this.type == 3 ? "مجيب جديد" :
        //       this.type == 4 ? "نقطة بيع جديدة" :
        //                         "طالب جديد",
        fetchingFunction: this.toggleCreateUserDialog,
        placeHolder: "ابحث عن حساب محدد",
        value: "",
        filterFunc: this.filterUsers
    });
  },
  created() {
    if(this.type <= 5 && this.type > 0) {
      this.fetchUsers({ id: 0, type: this.type });
    }
    else{
      this.$router.push('/error-404')
    }
  },
  methods: {
    ...mapActions(["toggleCreateUserDialog", "fetchUsers","updateUserPagination","removeUsers", "filterUsers"]),
    selectionChanged() {
      this.isNotSelectedRows = !this.$refs["accounts-table"].selectedRows.length;
    },
    getSelectedRow() {
      this.$refs["accounts-table"].selectedRows.forEach(element => {
        this.ids.push(element.id)
      });
      this.removeUsers(this.ids)
    },
  },
};
</script>
